<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.timer.is_not_interrupting') }}
        <el-checkbox :value="isNotInterrupting" @change="$emit('change_is_not_interrupting', $event)"></el-checkbox>
      </div>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.timer.type')}}
      </div>
      <el-select
          v-model="type"
          size="mini"
          :placeholder="$t('process_editor.element_properties.timer.type')"
          @change="onChange"
      >
        <el-option
            v-for="item in Object.keys($t('process_editor.element_properties.timer.types'))"
            :key="item"
            :label="$t(`process_editor.element_properties.timer.types.${item}`)"
            :value="item">
        </el-option>
      </el-select>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.timer.duration') }}
      </div>
      <el-input
          :disabled="!type"
          size="mini"
          v-model="period"
          @change="onChange"
      ></el-input>
    </div>
  </div>
</template>

<script>
import TimerUtils from '@/services/BPMNEditor/infrastructure/service/TimerUtils'

export default {
  name: 'TimerEditor',
  inject: ['getModeler'],
  props: {
    timer: {},
    isNotInterrupting: Boolean
  },
  data () {
    return {
      period: null,
      type: null
    }
  },
  mounted () {
    if (this.timer?.timeCycle) {
      this.type = 'cycle'
      this.period = this.timer.timeCycle.body
    } else if (this.timer?.timeDuration) {
      this.type = 'period'
      this.period = this.timer.timeDuration.body
    }
  },
  methods: {
    onChange () {
      TimerUtils.changeType(this.getModeler(), this.timer, this.type, this.period)
    }
  }
}
</script>
