import Modeler from 'bpmn-js/lib/Modeler'

export default class TimerUtils {
  public static changeTimer (modeler: Modeler, element: any, value: boolean) {
    const props = []
    if (value) {
      const cond = modeler.get('bpmnFactory').create('bpmn:TimerEventDefinition', {})
      cond.$parent = element
      props.push(cond)
    }

    modeler.get('modeling').updateProperties(element, {
      eventDefinitions: props
    })
  }

  public static getTimer (element: any): object {
    return (element.businessObject.get('eventDefinitions') || [])[0]
  }

  public static changeType (modeler: Modeler, timer: any, type: string, value?: string): void {
    const timerDefinitions = {
      period: 'timeDuration',
      cycle: 'timeCycle'
    }
    Object.values(timerDefinitions).forEach((_) => timer.set(_, undefined))
    const formalExpression = modeler.get('bpmnFactory').create('bpmn:FormalExpression', { body: value })
    formalExpression.$parent = timer

    timer.set(timerDefinitions[type], formalExpression)
  }
}
